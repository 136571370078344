import React, { lazy, Suspense } from 'react'
import { Blok } from 'storyblok-react'
import { PageStoryblok } from '../@types/storyblok'
import { Layout } from '../components/layout'
import { SEO } from '../components/seo'
import { useStoryblok } from '../hooks/useStoryblok'

const Page = lazy(() => import(/*webpackChunkName: "page"*/"../components/page"))

const PageTemplate = ({ pageContext, location }: { pageContext: any; location: Location }) => {
  const { story } = pageContext
  const {
    content: { seoTitle, seoDescription, openGraphImage, openGraphDescription, openGraphTitle },
  }: Blok<PageStoryblok> = story
  const storyLive = useStoryblok(story, location)

  return (
    <Layout>
      <SEO
        title={seoTitle}
        description={seoDescription}
        openGraphTitle={openGraphTitle}
        openGraphDescription={openGraphDescription}
        openGraphImage={openGraphImage?.filename}
      />
      <Suspense fallback={<></>}>
        <Page story={storyLive} location={location} />
      </Suspense>
    </Layout>
  )
}

export default PageTemplate
